<template>
  <div>
    <a-spin :spinning="loadingCount">
      <a-row :gutter="[16,16]">
        <a-col span="24">
          <div class="custom-card-box">
            <a-row :gutter="12">
              <a-col span="4">
                <div class="count-box">
                  <div>逝者总数</div>
                  <div class="count-value">{{ data.total | formatBigNumber }}</div>
                </div>
              </a-col>
              <a-col span="4">
                <div class="count-box">
                  <div>今日逝者数量</div>
                  <div class="count-value">{{ data.this_day_count | formatBigNumber }}</div>
                </div>
              </a-col>
              <a-col span="4">
                <div class="count-box">
                  <div>本月逝者数量</div>
                  <div class="count-value">{{ data.this_month_count | formatBigNumber }}</div>
                </div>
              </a-col>
              <a-col span="4">
                <div class="count-box">
                  <div>本年逝者数量</div>
                  <div class="count-value">{{ data.this_year_count | formatBigNumber }}</div>
                </div>
              </a-col>
              <a-col span="4">
                <div class="count-box">
                  <div>逝者最多月份</div>
                  <div class="count-value" v-if="data.most_month_count > 0">
                    {{ data.most_month }} - {{ data.most_month_count | formatBigNumber }}
                  </div>
                </div>
              </a-col>
              <a-col span="4">
                <div class="count-box">
                  <div>逝者最多年份</div>
                  <div class="count-value" v-if="data.most_year_count > 0">
                    {{ data.most_year }} - {{ data.most_year_count | formatBigNumber }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </a-spin>

    <a-row :gutter="[16,16]">
      <a-col span="8">
        <div class="custom-card-box">
          <div class="box-height">
            <!-- 性别统计图 -->
            <sex-type-statistics-chart />
            <death-reason-statistics-chart />
            <age-range-statistics-chart />
          </div>
        </div>
      </a-col>

      <a-col span="16">
        <div class="custom-card-box">
          <div class="box-height">
            <trend-chart />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findDeathStatisticCount } from '@/api/death_statistic'
import SexTypeStatisticsChart from '@/views/dead_data/SexTypeStatisticsChart'
import DeathReasonStatisticsChart from '@/views/dead_data/DeathReasonStatisticsChart'
import AgeRangeStatisticsChart from '@/views/dead_data/AgeRangeStatisticsChart'
import TrendChart from '@/views/dead_data/TrendChart'
export default {
  components: {
    SexTypeStatisticsChart,
    DeathReasonStatisticsChart,
    AgeRangeStatisticsChart,
    TrendChart
  },
  data() {
    return {
      loadingCount: true,
      loadingChart: true,
      total: 0,
      thisDayCount: 0,
      thisMonthCount: 0,
      thisYearCount: 0,
      mostMonthCount: 0,
      mostYearCount: 0,
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingCount = true
      findDeathStatisticCount().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loadingCount = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-box {
  background-color: @primary-color;
  height: 80px;
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  .count-value {
    color: @orange;
    font-weight: bold;
  }
}

.box-height {
  height: 800px;
}
</style>
