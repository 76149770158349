<template>
  <div>
    <div class="custom-padding-bottom-10px custom-flex custom-flex-row custom-justify-between">
      <div>
        <span class="title">逝者趋势</span>
        <a-range-picker
          @change="handleChange"
          :value="value"
          v-if="timeType === 'day'"
        />
      </div>
      <span>
        <a-radio-group
          v-model="timeType"
          @change="handleChangeTimeType"
          button-style="solid"
        >
          <a-radio-button value="day">
            日
          </a-radio-button>
          <a-radio-button value="month">
            月
          </a-radio-button>
          <a-radio-button value="year">
            年
          </a-radio-button>
        </a-radio-group>
      </span>
    </div>
    <sex-type-trend-chart :data="{time_type: timeType, date_begin: startTime, date_end: endTime}" />
    <death-reason-trend-chart :data="{time_type: timeType, date_begin: startTime, date_end: endTime}" />
    <age-range-trend-chart :data="{time_type: timeType, date_begin: startTime, date_end: endTime}" />
  </div>
</template>

<script>
import SexTypeTrendChart from '@/views/dead_data/SexTypeTrendChart'
import DeathReasonTrendChart from '@/views/dead_data/DeathReasonTrendChart'
import AgeRangeTrendChart from '@/views/dead_data/AgeRangeTrendChart'
import { formatYearMonthDay } from '@/utils/time'

export default {
  name: 'TrendChart',
  components: {
    SexTypeTrendChart,
    DeathReasonTrendChart,
    AgeRangeTrendChart
  },
  data() {
    return {
      timeType: 'day',
      loading: true,
      startTime: '',
      endTime: '',
      value: []
    }
  },
  methods: {
    handleChangeTimeType(e) {
      this.timeType = e.target.value
    },

    handleChange(value) {
      this.value = value
      if (this.timeType === 'day' && value !== '') {
        this.startTime = formatYearMonthDay(value[0])
        this.endTime = formatYearMonthDay(value[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  color: @primary-color;
  margin-right: 10px;
}
</style>
