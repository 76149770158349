import request from '@/utils/request'

// 查询逝者数量统计
export function findDeathStatisticCount(params) {
  return request({
    url: '/death_statistics/count',
    method: 'get',
    params: params
  })
}

// 查询逝者统计
export function findDeathStatistics(params) {
  return request({
    url: '/death_statistics',
    method: 'get',
    params: params
  })
}

// 查询逝者统计趋势
export function findDeathStatisticsTrend(params) {
  return request({
    url: '/death_statistics/trend',
    method: 'get',
    params: params
  })
}
